import { FC, memo, SVGProps } from 'react';
import cls from './Icon.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';

type SvgProps = Omit<SVGProps<SVGSVGElement>, 'onClick'>;

interface IIconBaseProps extends SvgProps {
  Svg: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  color?: string;
}

interface NoneClickableIconProps extends IIconBaseProps {
  clickable?: false;
}

interface ClickableIconProps extends IIconBaseProps {
  clickable: true;
  onClick: () => void;
}

type IconProps = NoneClickableIconProps | ClickableIconProps;

export const Icon: FC<IconProps> = memo(props => {
  const {
    className,
    clickable,
    height = 20,
    Svg,
    width = 20,
    color,
    ...otherProps
  } = props;

  const icon = (
    <Svg
      width={width}
      height={height}
      style={{ color, minHeight: height, minWidth: width }}
      className={classNames(cls.icon, {}, [!clickable ? className : ''])}
      {...otherProps}
      onClick={undefined}
    />
  );

  if (clickable)
    return (
      <button
        style={{ height, minHeight: height, minWidth: width, width }}
        type='button'
        className={classNames(cls.button, {}, [clickable ? className : ''])}
        onClick={props.onClick}
      >
        {icon}
      </button>
    );

  return icon;
});
