import { Variants } from '../model/types';
import cls from '../ui/text.module.css';

export const selectedVariantClass: Record<Variants, string> = {
  'body-l': cls['body-l'],
  'body-l-light': cls['body-l-light'],
  'body-l-medium': cls['body-l-medium'],
  'body-l-uppercase': cls['body-l-uppercase'],
  'body-m': cls['body-m'],
  'body-m-medium': cls['body-m-medium'],
  'body-s': cls['body-s'],
  'body-s-medium': cls['body-s-medium'],
  'body-s-uppercase': cls['body-s-uppercase'],
  'button-default': cls['button-default'],
  'button-default-v2': cls['button-default-v2'],
  'button-small': cls['button-small'],
  'button-small-v2': cls['button-small-v2'],
  h1: cls.h1,
  h2: cls.h2,
  'h2-bold': cls['h2-bold'],
  'h2-bold-uppercase': cls['h2-bold-uppercase'],
  'h2-light': cls['h2-light'],
  h3: cls.h3,
  h4: cls.h4,
  'h4-uppercase': cls['h4-uppercase'],
  h5: cls.h5,
  'numbers-l': cls['numbers-l'],
  'numbers-l-bold': cls['numbers-l-bold'],
  'numbers-m': cls['numbers-m'],
  'numbers-m-bold': cls['numbers-m-bold'],
  'numbers-s': cls['numbers-s'],
  'numbers-s-bold': cls['numbers-s-bold'],
  'numbers-x-l': cls['numbers-x-l'],
  'numbers-x-s': cls['numbers-x-s'],
  'numbers-x-s-bold': cls['numbers-x-s-bold'],
  subheading: cls.subheading,
  'subheading-bold-uppercase': cls['subheading-bold-uppercase'],
};
