import {
  AppRoutes,
  getRoutePartnershipPage,
  getRoutePaymentStatus,
  getRoutePaymentStep1,
  getRoutePaymentStep2,
  getRoutePaymentStep3Stripe,
  getRoutePrivacyPolicy,
  getRoutePromoPage,
  getRouteStripe,
  getRouteTermsOfUse,
  getRouteWelcome,
} from '@/shared/const/router';

// this func for mapping our routes, and return only route with !noHeaderFooter
export const pathWithHeader = (pathname: string) => {
  const arrWithHeader = [
    getRouteWelcome(),
    getRouteStripe(),
    getRoutePaymentStep1(),
    getRoutePaymentStep2(),
    getRoutePaymentStep3Stripe(),
    getRoutePaymentStatus(),
    getRoutePrivacyPolicy(),
    getRouteTermsOfUse(),
    getRoutePromoPage(),
    getRoutePartnershipPage(),
    '',
  ];

  return arrWithHeader.includes(pathname as AppRoutes);
};
