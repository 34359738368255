export enum AppRoutes {
  WELCOME_ROUTE = '/',
  STRIPE_ROUTE = '/stripe',
  PAYMENT_STEP_1 = '/payment_step_1',
  PAYMENT_STEP_2 = '/payment_step_2',
  PAYMENT_STEP_3_STRIPE = '/stripe_payment',
  PAYMENT_STATUS = '/payment_status',
  PRIVACY_POLICY = '/privacy_policy',
  TERMS_OF_USE = '/terms_of_use',
  MAIN = '/main',
  SUBSCRIBES = '/subscribes',
  SETTINGS = '/settings',
  MANUAL = '/manual',
  SUPPORT = '/support',
  FAQ = '/faq',
  WHATS_NEWS = '/whats_news',
  PROMO_PAGE = '/promo_page',

  SIGN_UP = '/sign_up',
  SIGN_IN = '/sign_in',

  PARTNER_RESET_PASSWORD = '/partners_program/reset_password',
  PARTNERSHIP_PAGE = '/partners_program/account',

  NOT_FOUND = '/not_found',
}

export const getRouteWelcome = () => AppRoutes.WELCOME_ROUTE;
export const getRouteStripe = () => AppRoutes.STRIPE_ROUTE;

export const getRoutePaymentStep1 = () => AppRoutes.PAYMENT_STEP_1;
export const getRoutePaymentStep2 = () => AppRoutes.PAYMENT_STEP_2;
export const getRoutePaymentStep3Stripe = () => AppRoutes.PAYMENT_STEP_3_STRIPE;
export const getRoutePaymentStatus = () => AppRoutes.PAYMENT_STATUS;

export const getRoutePrivacyPolicy = () => AppRoutes.PRIVACY_POLICY;
export const getRouteTermsOfUse = () => AppRoutes.TERMS_OF_USE;

export const getRoutePersonAccMain = () => AppRoutes.MAIN;
export const getRoutePersonAccSubscribes = () => AppRoutes.SUBSCRIBES;
export const getRoutePersonAccSettings = () => AppRoutes.SETTINGS;
export const getRoutePersonAccManual = () => AppRoutes.MANUAL;
export const getRoutePersonAccSupport = () => AppRoutes.SUPPORT;
export const getRoutePersonAccFAQ = () => AppRoutes.FAQ;
export const getRoutePersonAccWhatNews = () => AppRoutes.WHATS_NEWS;

export const getRoutePromoPage = () => AppRoutes.PROMO_PAGE;

export const getRoutePartnershipPage = () => AppRoutes.PARTNERSHIP_PAGE;

export const getRouteSignUp = () => AppRoutes.SIGN_UP;
export const getRouteSignIn = () => AppRoutes.SIGN_IN;

export const getRoutePartnerResetPassword = () =>
  AppRoutes.PARTNER_RESET_PASSWORD;

export const allRoutes = {
  getRoutePartnershipPage,
  getRoutePaymentStep1,
  getRoutePaymentStep2,
  getRoutePaymentStep3Stripe,
  getRoutePersonAccFAQ,
  getRoutePersonAccMain,
  getRoutePersonAccManual,
  getRoutePersonAccSettings,
  getRoutePersonAccSubscribes,
  getRoutePersonAccSupport,
  getRoutePersonAccWhatNews,
  getRoutePrivacyPolicy,
  getRoutePromoPage,
  getRouteSignIn,
  getRouteSignUp,
  getRouteStripe,
  getRouteWelcome,
};
