import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactNode,
} from 'react';
import cls from './flex.module.css';
import {
  FlexJustify,
  FlexAlign,
  FlexDirection,
  FlexWrap,
  ISeoTagName,
} from '../../model/types/typesStacks';
import { classNames, Mods } from '@/shared/lib/classNames/classNames';
import { mapMarginsSize } from '@/shared/styles/const';
import { Margins } from '@/shared/types/ui';

const justifyClass: Record<FlexJustify, string> = {
  around: cls['justify-around'],
  between: cls['justify-between'],
  center: cls['justify-center'],
  end: cls['justify-end'],
  start: cls['justify-start'],
  unset: cls['justify-unset'],
};

const alignClass: Record<FlexAlign, string> = {
  baseline: cls['align-baseline'],
  center: cls['align-center'],
  end: cls['align-end'],
  start: cls['align-start'],
  stretch: cls['align-stretch'],
};

const directionClass: Record<FlexDirection, string> = {
  column: cls['direction-column'],
  'column-reverse': cls['direction-column-reverse'],
  row: cls['direction-row'],
};

const wrapClass: Record<FlexWrap, string> = {
  nowrap: cls.nowrap,
  wrap: cls.wrap,
  'wrap-reverse': cls['wrap-reverse'],
};

export interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  as?: ISeoTagName;
  children: ReactNode;
  /**
   * justify-content
   */
  justify?: FlexJustify;
  /**
   * align-items
   */
  align?: FlexAlign;
  /**
   * flex-wrap
   */
  wrap?: FlexWrap;
  /**
   * flex-direction
   */
  direction: FlexDirection;
  gap?: Margins;
  /**
   * width: 100%
   */
  max?: boolean;
  style?: CSSProperties;
}

const FlexUi = forwardRef<RefDiv, FlexProps>((props, ref) => {
  const {
    as = 'div',
    className,
    children,
    direction = 'row',
    align = 'center',
    justify = 'start',
    gap,
    wrap = 'nowrap',
    max,
    style,
    ...otherProps
  } = props;

  const TagName = as;

  const classes = [
    className,
    justifyClass[justify],
    alignClass[align],
    directionClass[direction],
    wrapClass[wrap],
  ];

  const mods: Mods = {
    [cls.max]: Boolean(max),
  };

  return (
    <TagName
      ref={ref}
      style={{ gap: gap ? `${mapMarginsSize[gap]}rem` : undefined, ...style }}
      className={classNames(cls.flex, mods, classes)}
      {...otherProps}
    >
      {children}
    </TagName>
  );
});

export const Flex = memo(FlexUi);
