import { ElementType, useMemo } from 'react';
import cls from './text.module.css';
import { selectedVariantClass } from '../consts';
import { ITextProps, TTagName } from '../model/types';
import { classNames } from '@/shared/lib/classNames/classNames';

export const Text = <T extends ElementType = TTagName>(
  props: ITextProps<T>,
) => {
  const {
    as = 'p',
    children,
    align,
    className,
    variant,
    wrap = 'wrap',
    style,
    ...otherProps
  } = props;

  const TagName = as;

  const currentClass = useMemo(
    () =>
      classNames(cls.text, { [cls.nowrap]: wrap === 'nowrap' }, [
        className,
        selectedVariantClass[variant],
      ]),
    [className, variant, wrap],
  );

  return (
    <TagName
      className={currentClass}
      {...otherProps}
      style={{ textAlign: align, ...style }}
    >
      {children}
    </TagName>
  );
};
